@font-face {
	font-family: minerva-modern;
	src: url(https://use.typekit.net/af/2d15e1/00000000000000003b9afd25/27/l?subset_id=2&fvd=n4&v=3);
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
	margin: 0;
	padding: 0;

	font-family: minerva-modern;
	/* font-family: 'minerva-modern'; */
	/* font-family: 'Bebas Neue', sans-serif; */
	background-color: #2c434d;
}

.container {
	max-width: 800px;
	margin: 0 auto;
	padding: 40px;
	text-align: center;
	color: #d1c5b2;
	display: flex;
	flex-direction: column;

}

.form-label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	color: #d1c5b2;
}

h1 {
  font-family: 'Bebas Neue', sans-serif;
	color: #d1c5b2;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
  font-style: normal;
  font-weight: normal;

}

.title {
  font-size: 4em;
}

nav {
	background-color: #2c434d;
	padding: 20px;
	text-align: center;
	width: 100%;
} 

nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

nav ul li {
	margin: 0 20px;
}

nav ul li a {
	color: #d1c5b2;
	text-decoration: none;
	font-size: 18px;
	padding: 5px;
}

nav ul li a:hover {
	color: #eb8876;
}

form {
	margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
select {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: none;
	border-radius: 4px;
	background-color: #d1c5b2;
	color: #2c434d;
	box-sizing: content-box;
}


button {
	padding: 10px 20px;
	background-color: #eb8876;
	color: #2c434d;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

button:hover {
	background-color: #e66a50;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 4px;
	color: #d1c5b2;
}

p {
	color: white;
	font-size: 20px
}

.form-input {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: none;
	border-radius: 4px;
	background-color: #d1c5b2;
	color: #2c434d;
	box-sizing: content-box;
}

.form-container {
	max-width: 1000px;
	/* Adjust the value as needed */
	margin: 0 auto;
}


.alert {
	padding: 15px;
	margin-top: 20px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 16px;
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.alert.success {
	background-color: #d4edda;
	color: #155724;
	border: 1px solid #c3e6cb;
}

.alert.error {
	background-color: #f8d7da;
	color: #721c24;
	border: 1px solid #f5c6cb;
}

.alert span {
	margin-right: 10px;
	font-size: 20px;
}

.bebas-neue-regular {
	font-family: "Bebas Neue", sans-serif;
	font-weight: 400;
	font-style: normal;
}


.rdt_Table {
	background-color: #f8f9fa;
}

.rdt_TableHeader {
	background-color: #343a40;
	color: #fff;
}

.rdt_TableRow {
	background-color: #fff;
}

.rdt_TableRow:hover {
	background-color: #f1f1f1;
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
  }

/* Icon styling */
.tooltip .icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	/* background-color: #007bff; */
	color: #5f6d6d;
	border-radius: 50%;
	border-color: #5f6d6d;
	border-width: 2px;
	text-align: center;
	border-style: solid;
	line-height: 16px;
	font-size: 12px;
	margin-left: 5px;
}
  
  /* Tooltip text */
  .tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
  
	/* Position the tooltip text to the right */
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 100%;
	margin-left: 10px;
	transform: translateY(-50%);
  
	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #555 transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }