.react-select__control {
    background-color: #d1c5b2;
    border: none;
    border-radius: 4px;
    color: #2c434d;
    cursor: pointer;
  }
  
  .react-select__control:hover {
    background-color: #c4b7a1;
  }
  
  .react-select__single-value,
  .react-select__placeholder {
    color: #2c434d;
  }
  
  .react-select__menu {
    background-color: #d1c5b2;
    color: #2c434d;
  }
  
  .react-select__option {
    cursor: pointer;
  }
  
  .react-select__option--is-focused {
    background-color: #c4b7a1;
  }
  
  .react-select__indicator-separator {
    display: none;
  }